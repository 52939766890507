<template>
	<div id="projectNotice">
		<div class="title">
			<i></i>
			编辑项目公告
		</div>
		<quill-editor class="myQuillEditor" v-model="content" ref="myQuillEditor" :options="editorOption"
			@blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @change="onEditorChange($event)">
		</quill-editor>

		<div class="option">
			<el-button type="primary" @click="saveNotice">确认提交</el-button>
		</div>
	</div>
</template>

<script>
	import {
		officialGetProjectNotice,
		saveOrUpdateProjectNotice
	} from '@/request/api.js';

	// 引入vue-quill-editor富文本
	import {
		quillEditor
	} from 'vue-quill-editor'
	// 引入vue-quill-editor富文本css样式
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	// 引入自定义富文本样式
	// import '@/assets/css/quillEditor.css'
	// 将css注册到quill-editor中
	import * as Quill from 'quill'

	// 自定义鼠标停留按钮提示的交互
	const titleConfig = {
		'ql-bold': '加粗',
		'ql-color': '颜色',
		'ql-font': '字体',
		'ql-code': '插入代码',
		'ql-italic': '斜体',
		'ql-link': '添加链接',
		'ql-background': '背景颜色',
		'ql-size': '字体大小',
		'ql-strike': '删除线',
		'ql-script': '上标/下标',
		'ql-underline': '下划线',
		'ql-blockquote': '引用',
		'ql-header': '标题',
		'ql-indent': '缩进',
		'ql-list': '列表',
		'ql-align': '文本对齐',
		'ql-direction': '文本方向',
		'ql-code-block': '代码块',
		'ql-formula': '公式',
		'ql-image': '图片',
		'ql-video': '视频',
		'ql-clean': '清除字体样式'
	}
	// 自定义的字体，注意下面的内容要和css里面对应上
	var fonts = [
		'SimSun',
		'SimHei',
		'Microsoft-YaHei',
		'KaiTi',
		'FangSong',
		'Arial',
		'Times-New-Roman',
		'sans-serif'
	]
	// 自定义字号的大小，注意下面的内容要和css里面对应上
	var sizes = [false, '16px', '18px', '20px', '22px', '26px', '28px', '30px']
	//注册css自定义样式
	var Size = Quill.import('formats/size')
	Size.whitelist = sizes
	var Font = Quill.import('formats/font')
	Font.whitelist = fonts // 将字体加入到白名单
	Quill.register(Font, true)

	// 工具栏配置
	const toolbarOptions = [
		['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
		['blockquote', 'code-block'], // 引用  代码块-----['blockquote', 'code-block']
		[{
			header: 1
		}, {
			header: 2
		}], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
		[{
			list: 'ordered'
		}, {
			list: 'bullet'
		}], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
		[{
			script: 'sub'
		}, {
			script: 'super'
		}], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
		[{
			indent: '-1'
		}, {
			indent: '+1'
		}], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
		[{
			'direction': 'rtl'
		}], // 文本方向-----[{'direction': 'rtl'}]
		[{
			size: sizes
		}], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
		[{
			header: [1, 2, 3, 4, 5, 6, false]
		}], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
		[{
			color: []
		}, {
			background: []
		}], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
		[{
			font: fonts
		}], // 字体种类-----[{ font: [] }]
		[{
			align: []
		}], // 对齐方式-----[{ align: [] }]
		['clean'] // 清除文本格式-----['clean']
		['link', 'image'] // 链接、图片、视频-----['link', 'image', 'video']
	]
	export default {
		name: 'projectNotice',
		components: {
			quillEditor
		},
		data() {
			return {
				NoticeId: '',
				content: '', //富文本绑定的值
				editorOption: {
					placeholder: '您想说点什么？',
					theme: 'snow',
					modules: {
						toolbar: {
							container: toolbarOptions, // 工具栏
							handlers: {
								image: function(value) {
									if (value) {
										// 调用element的图片上传组件
										document.querySelector('.upload-img input').click()
									} else {
										this.quill.format('image', false)
									}
								}
							}
						}
					}
				},
			}
		},
		created() {
			this.getNotice()
		},
		methods: {
			getNotice() {
				officialGetProjectNotice({
					projectId: this.$route.query.pId
				}).then(res => {
					if (res.code == 200) {
						if (res.data.id) {
							this.NoticeId = res.data.id
							this.content = res.data.message && res.data.message != null && res.data.message !=
								"null" ? res.data.message : '';
						}
					}
				})
			},
			saveNotice() {
				console.log(this.content)
				let data = {
					projectId: this.$route.query.pId,
					message: this.content
				}
				if (this.NoticeId) data.id = this.NoticeId;
				saveOrUpdateProjectNotice(data).then(res => {
					if (res.code == 200) {
						this.$message.success('保存成功')
						this.$router.push({
							name: 'officialMeetinglist',
							query: {
								pId: this.$route.query.pId
							}
						})
					} else {
						this.$message.error(res.msg)
					}
				})
			},

			onEditorBlur() {
				//失去焦点事件
			},
			onEditorFocus() {
				//获得焦点事件
			},
			onEditorChange() {
				//内容改变事件
				this.$emit("input", this.content);
			},
			// 图片格式转换为base64
			getBase64(file) {
				return new Promise((resolve, reject) => {
					const reader = new FileReader()
					reader.readAsDataURL(file)
					reader.onload = () => resolve(reader.result)
					reader.onerror = error => reject(error)
				})
			},
			//上传图片，httpequest覆盖默认的上传行为，可以自定义上传的实现
			async httpRequest({
				file
			}) {
				let img = await this.getBase64(file)
				// 调用后端给的接口上传图片
				getUploadFilesImg({
					imgbase64: img
				}).then((res) => {
					// 获取富文本组件实例
					let quill = this.$refs.myQuillEditor.quill
					// 获取光标所在位置
					let length = quill.getSelection().index
					// 插入图片，res为服务器返回的图片链接地址
					quill.insertEmbed(length, 'image', res.path)
					// 调整光标到最后
					quill.setSelection(length + 1)
				}).catch((err) => {
					console.log(err)
				})
			},
			//定义鼠标停留按钮提示事件，在mounted里调用
			addQuillTitle() {
				const oToolBar = document.querySelector('.ql-toolbar')
				const aButton = oToolBar.querySelectorAll('button')
				const aSelect = oToolBar.querySelectorAll('select')
				aButton.forEach(function(item) {
					if (item.className === 'ql-script') {
						item.value === 'sub' ? item.title = '下标' : item.title = '上标'
					} else if (item.className === 'ql-indent') {
						item.value === '+1' ? item.title = '向右缩进' : item.title = '向左缩进'
					} else {
						item.title = titleConfig[item.classList[0]]
					}
				})
				aSelect.forEach(function(item) {
					item.parentNode.title = titleConfig[item.classList[0]]
				})
			}
		}
	}
</script>

<style>
	#projectNotice {
		padding: 20px 30px;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		.title {
			font-size: 20px;
			padding: 0 20px 0 30px;
			position: relative;
			margin: 10px 20px 20px 0;

			i {
				top: 50%;
				left: 0px;
				width: 20px;
				height: 20px;
				position: absolute;
				border-radius: 50%;
				background-color: #3563E9;
				border: 5px solid #C2D0F8;
				transform: translateY(-50%);
			}
		}


		.option {
			display: flex;
			justify-content: center;
			margin-bottom: 20px;
			margin-top: 55px;
		}
	}

	/* 编辑器高度及背景色 */
	.myQuillEditor {

		height: 60vh;
	}
</style>